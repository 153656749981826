





















































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { PatrolEntityModel, PatrolQueryModel, PatrolHistoryQueryModel, PatrolStatus, filterPartrolStatus } from './model/patrol-entity';
import PatrolService from './service/patrol';
import PatrolDetailDrawer from './patrol-detail.vue';
import PartolHandleDialog from './patrol-handle-dialog.vue';
import PartolExportDialog from './patrol-export-dialog.vue';

@Component({
    components: {
        'PatrolDetailDrawer': PatrolDetailDrawer,
        'PartolHandleDialog': PartolHandleDialog,
        'PartolExportDialog': PartolExportDialog
    }
})
export default class PatrolListComponent extends TableDialogFormComponent<PatrolEntityModel, any> {
    // PatrolEntityModel = PatrolEntityModel;
    PatrolStatus = PatrolStatus;
    queryType: boolean = true;
    filterPartrolStatus = filterPartrolStatus;
    created() {
        this.queryTypeChange();
        this.getList();
    }

    queryTypeChange() {
        this.listData = null;
        this.service = null;
        if (this.queryType) {
            this.initTable({
                listFunc: PatrolService.query,
                queryModel: new PatrolQueryModel(),
                tableColumns: PatrolEntityModel.getTableColumns()
            });
        } else {
            this.initTable({
                listFunc: PatrolService.queryHistory,
                queryModel: new PatrolHistoryQueryModel(),
                tableColumns: PatrolEntityModel.getHistoryTableColumns()
            });
        }
    }

    detailClick(model: any) {
        (this.$refs.detailDialog as PatrolDetailDrawer).drawerShow(model);
    }

    handleClick(model: any) {
        if (this.queryType) {
            this.detailClick(model);
        } else {
            (this.$refs.handleDialog as PartolHandleDialog).dialogOpen(model);
        }
    }

    exportClick() {
        (this.$refs.exportDialog as PartolExportDialog).dialogOpen();
    }
}

