

















import { Component, Prop, Ref } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import FormComponent from '@common-src/mixins/form-component';
import { ViewModeType } from '@common-src/model/enum';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel } from '@common-src/model/form-control';
import PatrolService from './service/patrol';
import moment from 'moment';

@Component
export default class PartolHandleDialog extends BaseComponent {
    patrolModel: any;

    /**
     * 表单组件
     */
    @Ref(JTL.CONSTANT.DEFAULT_FORM_NAME)
    jtlForm: FormComponent<any>;

    /**
     * 弹窗打开/关闭标识
     */
    protected dialogVisible: boolean = false;

    dialogOpen(model) {
        this.patrolModel = model;
        this.dialogVisible = true;
        this.$nextTick(() => {
            if (this.jtlForm) {
                const formControls = [
                    {
                        key: 'pointId',
                        label: '巡更点',
                        type: FormControlType.TEXT,
                        required: true,
                        readonly: true,
                        value: model.pointName
                    },
                    {
                        key: 'patrolTime',
                        label: '巡更时间',
                        type: FormControlType.SELECT_DATE,
                        required: true,
                        value: moment(model.patrolTime)
                    },
                    {
                        key: 'remark',
                        label: '备注',
                        type: FormControlType.TEXT_AREA,
                        required: true,
                        value: model.remark
                    }
                ];
                this.jtlForm.initFormByControls(formControls, ViewModeType.UPDATE);
            }
        });
    }

    dialogOK() {
        return this.jtlForm.submitForm().then((ret) => {
            const handleList = [
                {
                    id: this.patrolModel.id,
                    patrolTime: this.jtlForm.formModel.patrolTime?.toDate().getTime(),
                    remark: this.jtlForm.formModel.remark
                }
            ];
            return PatrolService.batchHandle(handleList).then(res => {
                this.showMessageSuccess('处理巡更记录成功');
                this.dialogClose();
            });
        }).catch(err => {
            throw err;
        });
    }

    dialogClose() {
        this.dialogVisible = false;
    }
}
