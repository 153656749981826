

















import { Component, Prop, Ref } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import FormComponent from '@common-src/mixins/form-component';
import { PeriodTypeEnum, ViewModeType } from '@common-src/model/enum';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel } from '@common-src/model/form-control';
import PatrolService from './service/patrol';
import moment from 'moment';

@Component
export default class PartolExportDialog extends BaseComponent {
    /**
     * 表单组件
     */
    @Ref(JTL.CONSTANT.DEFAULT_FORM_NAME)
    jtlForm: FormComponent<any>;

    /**
     * 弹窗打开/关闭标识
     */
    protected dialogVisible: boolean = false;

    dialogOpen() {
        this.dialogVisible = true;
        this.$nextTick(() => {
            if (this.jtlForm) {
                const formControls = [
                    {
                        key: 'exportType',
                        label: '导出方式',
                        type: FormControlType.RADIO_GROUP,
                        options: [
                            { name: '巡更任务', value: 'TASK' },
                            { name: '巡更记录', value: 'TASK_ITEM' }
                        ],
                        required: true,
                        value: 'TASK'
                    },
                    {
                        key: 'planId',
                        label: '巡更计划',
                        type: FormControlType.SELECT,
                        optionsPromise: PatrolService.getAllPlan,
                        required: true
                    },
                    {
                        key: 'dateRange',
                        label: '开始时间范围',
                        type: FormControlType.SELECT_DATERANGE,
                        showTime: true,
                        required: true
                    }
                ];
                this.jtlForm.initFormByControls(formControls, ViewModeType.UPDATE);
            }
        });
    }

    dialogOK() {
        return this.jtlForm.submitForm().then((ret) => {
            const params = {
                exportType: this.jtlForm.formModel.exportType,
                planId: this.jtlForm.formModel.planId,
                startTime: this.jtlForm.formModel.dateRange[0]?.toDate().getTime(),
                endTime: this.jtlForm.formModel.dateRange[1]?.toDate().getTime()
            };
            return PatrolService.exportExcel(params).then(res => {
                this.showMessageSuccess('导出成功');
                this.dialogClose();
            });
        }).catch(err => {
            throw err;
        });
    }

    dialogClose() {
        this.dialogVisible = false;
    }
}
