













































































import { Component, Mixins } from 'vue-property-decorator';
import DrawerComponent from '@common-src/mixins/drawer-component';
import TableComponent from '@common-src/mixins/table-component';
import { PatrolHandleEntityModel, PatrolHistoryQueryModel, PatrolStatus, filterPartrolStatus } from './model/patrol-entity';
import PatrolService from './service/patrol';
import moment, { Moment } from 'moment';
// import PartolHandleDialog from './patrol-handle-dialog.vue';

@Component
export default class PatrolDetailDrawer extends Mixins(TableComponent, DrawerComponent) {
    patrolModel: any = null;
    isEditMode: boolean = false;
    PatrolStatus = PatrolStatus;
    filterPartrolStatus = filterPartrolStatus;
    // defaultTime: Moment = null;

    created() {
        this.pageSize = 999;
        this.isAutoQuery = false;
        this.initTable({
            listFunc: PatrolService.getTaskDetail,
            queryModel: null,
            tableColumns: PatrolHandleEntityModel.getTableColumns()
        });
    }
    drawerShow(model: any) {
        // if (model?.planExecuteTime) {
        //     this.defaultTime = moment(model.planExecuteTime);
        // }
        this.isEditMode = false;
        this.listData = null;
        this.patrolModel = model;
        this.queryModel = this.patrolModel.id;
        this.drawerOpen(model);
        this.getList();
    }

    // handleClick(model) {
    //     (this.$refs.formDialog as PartolHandleDialog).dialogOpen(model);
    // }
    saveClick() {
        if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
            this.showMessageWarning('请先选择巡更记录');
            return;
        }
        const selectedList = _.filter(this.listData, (item: any) => this.selectedRowKeys.indexOf(item.uuid) > -1);
        const handleList = _.map(selectedList, (item: any) => ({
            id: item.id,
            patrolTime: item.patrolTime || null,
            remark: item.remark
        }));
        return PatrolService.batchHandle(handleList).then(res => {
            this.isEditMode = false;
            this.showMessageSuccess('处理巡更记录成功');
            this.getList().then(res => {
                this.patrolModel.checkedNum = res.checkedNum;
                this.patrolModel.pointTotal = res.pointTotal;
                this.patrolModel.state = res.state;
            });
        });
    }
}
