import { BaseEntityModel } from '@common-src/model/base-model';
import { QueryPageModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { dateFormat, dateFromNow, text as textFormat } from '@common-src/filter';
import moment, { Moment } from 'moment';
import { getEndTimeByIntervalType } from './parking-report-model';
import { PeriodTypeEnum } from '@common-src/model/enum';
import PatrolService from '../service/patrol';

export enum PatrolStatus {
    NORMAL = 'NORMAL',
    ABNORMAL = 'ABNORMAL'
}

const partrolStatusList = [
    { name: '正常', value: PatrolStatus.NORMAL },
    { name: '异常', value: PatrolStatus.ABNORMAL }
];

export function filterPartrolStatus(partrolStatus: PatrolStatus) {
    return _.get(_.find(partrolStatusList, item => item.value === partrolStatus), 'name');
}

export class PatrolEntityModel extends BaseEntityModel {
    static getTableColumns() {
        return [
            {
                title: '巡更任务编号',
                dataIndex: 'taskNo'
            },
            {
                title: '巡更计划',
                dataIndex: 'planName'
            },
            {
                title: '巡更点完成情况',
                dataIndex: 'checkedRate',
                customRender: (text, record, index) => {
                    return `${textFormat(record.checkedNum)}/${textFormat(record.pointTotal)}`;
                }
            },
            {
                title: '巡更任务状态',
                dataIndex: 'stateDesc',
                scopedSlots: { customRender: 'stateDesc' }
            },
            {
                title: '操作',
                dataIndex: 'action',
                width: 100,
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    static getHistoryTableColumns() {
        return [
            {
                title: '巡更任务编号',
                dataIndex: 'taskNo'
            },
            {
                title: '巡更计划',
                dataIndex: 'planName'
            },
            {
                title: '巡更点',
                dataIndex: 'pointName'
            },
            {
                title: '巡更时间',
                dataIndex: 'patrolTime',
                customRender: (text, record, index) => {
                    if (text) {
                        return `${dateFormat(text)} (${dateFromNow(text)})`;
                    }
                    return '-';
                }
            },
            {
                title: '巡更记录状态',
                dataIndex: 'stateDesc'
            },
            {
                title: '备注',
                dataIndex: 'remark'
            }
            // {
            //     title: '操作',
            //     dataIndex: 'action',
            //     scopedSlots: { customRender: 'action' }
            // }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        return this;
    }

    toService() {
        const data: any = super.toService();
        return data;
    }
}

export class PatrolHandleEntityModel extends BaseEntityModel {
    taskNo: string = undefined;
    pointId: string = undefined;
    pointName: string = undefined;
    patrolTime: number = undefined;
    barNumber: string = undefined;
    state: string = undefined;
    stateDesc: string = undefined;
    remark: string = undefined;
    planName: string = undefined;

    get patrolTimeMoment() {
        if (this.patrolTime) {
            return moment(this.patrolTime);
        }
        return undefined;
    }
    set patrolTimeMoment(val: Moment) {
        if (val) {
            this.patrolTime = val.toDate().getTime();
        } else {
            this.patrolTime = undefined;
        }
    }

    static getTableColumns() {
        return [
            {
                title: '序号',
                scopedSlots: { customRender: 'index' },
                width: 80
            },
            {
                title: '巡更点',
                dataIndex: 'pointName',
                width: 250
            },
            // {
            //     title: '巡更时间',
            //     dataIndex: 'patrolTime',
            //     customRender: (text, record, index) => {
            //         if (text) {
            //             return `${dateFormat(text)} (${dateFromNow(text)})`;
            //         }
            //         return '-';
            //     }
            // },
            {
                title: '巡更时间',
                dataIndex: 'patrolTime',
                width: 200,
                scopedSlots: { customRender: 'patrolTime' }
            },
            {
                title: '棒号',
                dataIndex: 'barNumber',
                width: 120
            },
            {
                title: '巡更记录状态',
                dataIndex: 'stateDesc',
                width: 120
            },
            {
                title: '备注',
                dataIndex: 'remark',
                width: 300,
                scopedSlots: { customRender: 'remark' }
            }
        ];
    }
}

export class PatrolQueryModel extends QueryPageModel {
    @QueryControl({
        label: '巡更计划',
        type: QueryControlType.SELECT,
        optionsPromise: PatrolService.getAllPlan,
        hasAllOption: true
    })
    planId: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '巡更编号',
        type: QueryControlType.TEXT
    })
    taskNo: string = undefined;

    @QueryControl({
        label: '状态',
        type: QueryControlType.SELECT,
        options: partrolStatusList,
        hasAllOption: true
    })
    state: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '巡更开始时间',
        type: QueryControlType.SELECT_DATE,
        showTime: false
    })
    dateRange: Array<Moment> = JTL.CONSTANT.LAST_7_DAY();

    toService() {
        if (this.dateRange && this.dateRange.length === 2) {
            return {
                params: {
                    endTime: getEndTimeByIntervalType(this.dateRange[1], PeriodTypeEnum.DAY),
                    startTime: this.dateRange[0]?.startOf('day').toDate().getTime(),
                    taskNo: this.taskNo,
                    planId: this.planId,
                    state: this.state
                }
            };
        }
        return {
            params: {
                taskNo: this.taskNo,
                planId: this.planId,
                state: this.state
            }
        };
    }
}

export class PatrolHistoryQueryModel extends QueryPageModel {
    @QueryControl({
        label: '巡更计划',
        type: QueryControlType.SELECT,
        optionsPromise: PatrolService.getAllPlan,
        hasAllOption: true
    })
    planId: string = undefined;

    @QueryControl({
        label: '巡更编号',
        type: QueryControlType.TEXT
    })
    taskNo: string = undefined;

    @QueryControl({
        label: '巡更点',
        type: QueryControlType.SELECT,
        optionsPromise: PatrolService.getAllPoint,
        hasAllOption: true
    })
    pointId: string = undefined;

    @QueryControl({
        label: '巡更开始时间',
        type: QueryControlType.SELECT_DATE,
        showTime: false
    })
    dateRange: Array<Moment> = JTL.CONSTANT.LAST_7_DAY();

    toService() {
        if (this.dateRange && this.dateRange.length === 2) {
            return {
                params: {
                    endTime: getEndTimeByIntervalType(this.dateRange[1], PeriodTypeEnum.DAY),
                    startTime: this.dateRange[0]?.startOf('day').toDate().getTime(),
                    taskNo: this.taskNo,
                    planId: this.planId,
                    pointId: this.pointId
                }
            };
        }
        return {
            params: {
                taskNo: this.taskNo,
                planId: this.planId,
                pointId: this.pointId
            }
        };
    }
}
