var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        title: _vm.drawerTitle,
        placement: _vm.placement,
        width: _vm.width,
        closable: _vm.closable,
        visible: _vm.drawerVisible,
        "destroy-on-close": true,
      },
      on: { close: _vm.drawerClose },
    },
    [
      _c("div", { staticClass: "patrol-detail-component table-component" }, [
        _vm.patrolModel
          ? _c(
              "div",
              { staticClass: "page-body page-body-bg-white" },
              [
                _c(
                  "a-descriptions",
                  {
                    staticClass: "tab-margin-16",
                    attrs: { bordered: "", title: "基本信息", column: 2 },
                  },
                  [
                    _c(
                      "a-descriptions-item",
                      { attrs: { label: "巡更任务编号", span: 1 } },
                      [_vm._v(" " + _vm._s(_vm.patrolModel.taskNo) + " ")]
                    ),
                    _c(
                      "a-descriptions-item",
                      { attrs: { label: "巡更点完成情况", span: 1 } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("text")(_vm.patrolModel.checkedNum)) +
                            " / " +
                            _vm._s(_vm._f("text")(_vm.patrolModel.pointTotal)) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "a-descriptions-item",
                      { attrs: { label: "巡更计划", span: 1 } },
                      [_vm._v(" " + _vm._s(_vm.patrolModel.planName) + " ")]
                    ),
                    _c(
                      "a-descriptions-item",
                      { attrs: { label: "状态", span: 1 } },
                      [
                        _c(
                          "span",
                          {
                            class: {
                              "jtl-del-link":
                                _vm.patrolModel.state ===
                                _vm.PatrolStatus.ABNORMAL,
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("text")(
                                  _vm.filterPartrolStatus(_vm.patrolModel.state)
                                )
                              )
                            ),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c("br"),
                _c(
                  "div",
                  {
                    staticClass:
                      "tab-margin-16 ant-descriptions ant-descriptions-bordered",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "ant-descriptions-title inline-block" },
                      [_vm._v("巡更记录")]
                    ),
                    _c(
                      "div",
                      { staticClass: "patrol-history-wrapper" },
                      [
                        _c(
                          "jtl-button",
                          {
                            attrs: {
                              type: "primary",
                              "click-prop": _vm.saveClick.bind(this),
                            },
                          },
                          [_vm._v("批量处理")]
                        ),
                      ],
                      1
                    ),
                    _c("a-table", {
                      attrs: {
                        loading: _vm.listLoading,
                        columns: _vm.tableColumns,
                        "data-source": _vm.listData,
                        pagination: false,
                        scroll: _vm.tableScroll,
                        "row-key": _vm.TableRowKey,
                        size: _vm.TableSize,
                        "row-selection": {
                          selectedRowKeys: _vm.selectedRowKeys,
                          onChange: _vm.onSelectChange,
                          onSelect: _vm.onSelect,
                        },
                      },
                      on: { change: _vm.tableChange },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "index",
                            fn: function (text, record, index) {
                              return [_vm._v(" " + _vm._s(index + 1) + " ")]
                            },
                          },
                          {
                            key: "patrolTime",
                            fn: function (text, record) {
                              return [
                                _vm.selectedRowKeys &&
                                _vm.selectedRowKeys.indexOf(record.uuid) > -1
                                  ? _c("a-date-picker", {
                                      attrs: {
                                        "default-value": null,
                                        "show-time": true,
                                        placeholder: "请选择巡更时间",
                                      },
                                      model: {
                                        value: record.patrolTimeMoment,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            record,
                                            "patrolTimeMoment",
                                            $$v
                                          )
                                        },
                                        expression: "record.patrolTimeMoment",
                                      },
                                    })
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("dateFormat")(
                                            record.patrolTime,
                                            "YYYY-MM-DD HH:mm:ss"
                                          )
                                        )
                                      ),
                                    ]),
                              ]
                            },
                          },
                          {
                            key: "remark",
                            fn: function (text, record) {
                              return [
                                _vm.selectedRowKeys &&
                                _vm.selectedRowKeys.indexOf(record.uuid) > -1
                                  ? _c("a-input", {
                                      attrs: { placeholder: "请输入备注" },
                                      model: {
                                        value: record.remark,
                                        callback: function ($$v) {
                                          _vm.$set(record, "remark", $$v)
                                        },
                                        expression: "record.remark",
                                      },
                                    })
                                  : _c("span", [_vm._v(_vm._s(text))]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2692661945
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }