
import { PatrolEntityModel, PatrolQueryModel, PatrolHandleEntityModel, PatrolHistoryQueryModel } from '../model/patrol-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, del, put, download } from '@common-src/service/request';

const URL_PATH = `${BIBIZ_BASE_REQUEST_PATH}/patrolSystem`;

class PatrolService implements ICRUDQ<PatrolEntityModel, PatrolQueryModel> {
    async create(model: PatrolEntityModel):Promise<PatrolEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<PatrolEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new PatrolEntityModel().toModel(res);
    }

    async update(model: PatrolEntityModel):Promise<PatrolEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: PatrolEntityModel):Promise<PatrolEntityModel> {
        const url = `${URL_PATH}/delete/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: PatrolQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/task/query`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        return res;
    }

    async queryHistory(query?: PatrolHistoryQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/task/item/query`;
        const params = Object.assign({ page, limit }, query?.toService?.() || query);
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new PatrolHandleEntityModel().toModel(item));
        return res;
    }

    async getTaskDetail(taskId: string, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/task/${taskId}`;
        const res = await get(url);
        res.items = _.map(res?.taskItemList, item => item = new PatrolHandleEntityModel().toModel(item));
        return res;
    }

    async getAllPlan():Promise<Array<{name: string, value: string}>> {
        const url = `${URL_PATH}/plan/query`;
        const res = await post(url, { page: 1, limit: 999, params: {} });
        return _.map(res?.items, item => {
            return {
                name: item.name,
                value: item.id
            };
        });
    }

    async getAllPoint():Promise<Array<{name: string, value: string}>> {
        const url = `${URL_PATH}/point/query`;
        const res = await post(url, { page: 1, limit: 999, params: {} });
        return _.map(res?.items, item => {
            return {
                name: item.name,
                value: item.entityId
            };
        });
    }

    async batchHandle(params):Promise<any> {
        const url = `${URL_PATH}/task/item/anomaly`;
        const res = await put(url, params);
        return res;
    }

    async exportExcel(params):Promise<any> {
        const url = `${URL_PATH}/task/export`;
        const res = await download(url, params, 'POST');
        return res;
    }
}

export default new PatrolService();
